import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

// TODO - move to https://www.gatsbyjs.com/plugins/gatsby-plugin-image
// TODO - update our fields so we can deconstruct "alt" (set as image_alt_text, etc. atm)
const SmartImage = ({ alt = '', imageInfo, style = {} }) => {
  const { childImageSharp, extension, image, publicURL } = imageInfo;
  const imageAlt = !!imageInfo?.alt ? imageInfo.alt : alt;

  if (!!image && !!image.childImageSharp) {
    let childImageSharp = image.childImageSharp;

    if (childImageSharp.fluid) {
      return <Img style={style} fluid={childImageSharp.fluid} alt={imageAlt} />
    } else if (childImageSharp.fixed) {
      return <Img style={style} fixed={childImageSharp.fixed} alt={imageAlt} />
    }
  }

  if (!!childImageSharp) {
    if (childImageSharp.fluid) {
      return <Img style={style} fluid={childImageSharp.fluid} alt={imageAlt} />
    } else if (childImageSharp.fixed) {
      return <Img style={style} fixed={childImageSharp.fixed} alt={imageAlt} />
    }
  }

  if (!!image && typeof image === 'string')
    return <img style={style} src={image} alt={imageAlt} />

  if (!childImageSharp && extension === 'svg') {
    return <img style={style} src={publicURL} alt={imageAlt} />
  }

  return null
}

SmartImage.propTypes = {
  imageInfo: PropTypes.shape({
    childImageSharp: PropTypes.object,
    style: PropTypes.object,
    fluid: PropTypes.string,
    extension: PropTypes.string
  }).isRequired,
}

export default SmartImage;
