import { format } from 'date-fns';
import { graphql, Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import * as _ from 'lodash';
import { lighten, setLightness } from 'polished';
import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Footer } from '../components/Footer';
import SiteNav, { SiteNavMain } from '../components/header/SiteNav';
import { Logo } from '../components/Logo';
import { Subscribe } from '../components/subscribe/Subscribe';
import { Wrapper } from '../components/Wrapper';
import SmartImage from '../components/SmartImage';
import IndexLayout from '../layouts';
import {
  inner,
  innerNarrow,
  outer,
  outerFull,
  PostFeed,
  Posts,
  Section,
  SectionContent,
  SectionContentColumn,
  SectionContentContent,
  SectionContentTitle,
  SectionIntro,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteHeaderStyles,
  outerBordered,
} from '../styles/shared';
import { colors } from '../styles/colors';
import config from '../website-config';
import NominationForm from '../components/forms/NominationForm';

export interface Author {
  id: string;
  bio: string;
  avatar: {
    children: Array<{
      fluid: FluidObject;
    }>;
  };
}

interface InitiativeTemplateProps {
  location: Location;
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
      };
    };
  };
}

const InitiativeTemplate = ({ data, _pageContext, location }: InitiativeTemplateProps) => {
  const initiative = data.markdownRemark;
  const frontmatter = initiative.frontmatter;

  return (
    <IndexLayout className="post-template">
      <Helmet>
        <html lang={config.lang} />
        <title>{config.title} Initiative | {frontmatter.title}</title>

        <meta name="description" content={frontmatter.homepage_excerpt} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.homepage_excerpt} />
        <meta property="og:url" content={config.siteUrl + location.pathname} />
        <meta property="article:published_time" content={frontmatter.date} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        {config.facebook && <meta property="article:author" content={config.facebook} />}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={frontmatter.homepage_excerpt} />
        <meta name="twitter:url" content={config.siteUrl + location.pathname} />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        {config.twitter && (
          <meta
            name="twitter:creator"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
      </Helmet>

      <Wrapper>
        <div
            css={[outer, SiteHeader, SiteHeaderStyles]}
            className="site-header-background"
          >
          <div css={inner}>
            <SiteNav isHome />
            <SiteHeaderContent className="site-header-content">
              <SiteTitle className="site-title">
                { frontmatter.title }
              </SiteTitle>
              <h3>{ frontmatter.tagline }</h3>
            </SiteHeaderContent>
          </div>
        </div>
        <main id="site-main">
          <div css={[SiteMain, outerFull]}>
            <div css={innerNarrow}>
              <SectionIntro dangerouslySetInnerHTML={{ __html: frontmatter.intro }} />
            </div>
          </div>

          <div css={[SiteMain, outer]}>
            {frontmatter.content_with_image.map((content_with_image, index) => {
              return (
                <Section css={inner} key={index}>
                  <SectionContent>
                    <SectionContentColumn>
                      <SectionContentContent>
                        <SmartImage imageInfo={content_with_image.image} />
                      </SectionContentContent>
                    </SectionContentColumn>

                    <SectionContentColumn>
                      {/* TODO - this doesn't feel right because of the dangerously */}
                      {/* Use AST/REHYPE? https://www.gatsbyjs.com/plugins/gatsby-remark-component/ */}
                      <SectionContentContent dangerouslySetInnerHTML={{ __html: content_with_image.content }} />
                    </SectionContentColumn>
                  </SectionContent>
                </Section>
              )
            })}
          </div>

          <div css={[outerFull, outerBordered]}>
            <Section css={inner}>
              <SectionIntro>
                <h3>{frontmatter.partners_header}</h3>
                <p>{frontmatter.partners_aside}</p>
              </SectionIntro>

              <SectionContent>
                {frontmatter.image_with_config.map((image_w_config, index) => {
                  return (
                    <SectionContentColumn>
                      <SectionContentContent>
                        { image_w_config.image_link.length > 0 && (
                          <a
                            href={image_w_config.image_link}
                            rel='noopener'
                            target={image_w_config.image_link_target_blank ? '_blank' : ''}
                          >
                            <SmartImage imageInfo={image_w_config.image} alt={image_w_config.image_alt_text} />
                          </a>
                        )}

                        { image_w_config.image_link.length === 0 && (
                          <SmartImage imageInfo={image_w_config.image} alt={image_w_config.image_alt_text} />
                        )}
                      </SectionContentContent>
                    </SectionContentColumn>
                  );
                })}
              </SectionContent>
            </Section>
          </div>

          <div css={[SiteMain, outer]}>
            <Section css={inner}>
              <SectionIntro>
                <h3>{frontmatter.form_header}</h3>
              </SectionIntro>
              <SectionContent>
                <SectionContentColumn>
                  <SectionContentContent>
                    <NominationForm initiative={frontmatter.title} />
                  </SectionContentContent>
                </SectionContentColumn>

                <SectionContentColumn>
                  {/* TODO - this doesn't feel right because of the dangerously */}
                  {/* Use AST/REHYPE? https://www.gatsbyjs.com/plugins/gatsby-remark-component/ */}
                  <SectionContentContent dangerouslySetInnerHTML={{ __html: frontmatter.form_aside }} />
                </SectionContentColumn>
              </SectionContent>
            </Section>
          </div>
        </main>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const query = graphql`
  query($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tagline
        intro: html(from: "intro")
        content_with_image {
          content: html(from: "content")
          image_alt_text
          image_position
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 300) {
                base64
                originalImg
                originalName
                srcSetWebp
                srcWebp
                tracedSVG
              }
            }
          }
        }
        form_header
        form_aside: html(from: "form_aside")
        form_component
        partners_aside
        partners_header
        image_with_config {
          image_alt_text
          image_link
          image_link_target_blank
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default InitiativeTemplate;
