// Render Prop
import React from 'react';
import { Formik, Form, Field, FormikHelpers as FormikActions } from 'formik';
// import axios from 'axios';

import {
  alert,
  alertSuccess,
  alertError,
  form,
  styledField,
  styledForm,
  styledInput,
  styledSubmit
} from '../../styles/forms';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
}

const NominationForm = ({initiative}) => (
  <>
    <Formik
      initialValues={
        {
          email: '',
          n_first_name: '',
          n_last_name: '',
          n_email: '',
          n_instagram: '',
          n_reason: ''
        }
      }
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { resetForm, setStatus }) => {
        // TODO - use axios here?
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({ 'form-name': 'nomination', ...values })
        })
        .then(res => {
          setStatus(res.status)
          if (res.status === 200) {
            resetForm()
            setStatus({
              sent: true,
              msg: 'Thank you for your nomination!'
            })
          }
        })
        .catch(_err => {
          resetForm()
          setStatus({
            sent: false,
            msg: 'There was a problem submitting the form. Please contact sayhi@jihi.com for help if the problem persists.'
          })
        })
      }}
    >
      {({ isSubmitting, status }) => (
        <>
          <Form data-netlify='true' name='nomination' css={form}>
            <fieldset>
              <Field
                name='initiative'
                type='hidden'
                value={initiative}
              />

              <Field
                name='form-name'
                type='hidden'
                value='nomination'
              />

              <div css={styledField}>
                <label>Your Email</label>
                <Field
                  name='email'
                  type='email'
                  required={true}
                  css={styledInput}
                  placeholder='Enter your email'
                />
              </div>
            </fieldset>

            <fieldset>
              <legend>Nominee Information</legend>

              <div css={styledField}>
                <label>First Name</label>
                <Field
                  name='n_first_name'
                  type='text'
                  required={true}
                  css={styledInput}
                />
              </div>

              <div css={styledField}>
                <label>Last Name</label>
                <Field
                  name='n_last_name'
                  type='text'
                  required={true}
                  css={styledInput}
                />
              </div>

              <div css={styledField}>
                <label>Email</label>
                <Field
                  name='n_email'
                  type='text'
                  required={true}
                  css={styledInput}
                />
              </div>

              <div css={styledField}>
                <label>Instagram</label>
                <Field
                  name='n_instagram'
                  type='text'
                  required={true}
                  css={styledInput}
                />
              </div>

              <div css={styledField}>
                <label>Describe why you're nominating this person</label>
                <Field
                  name='n_reason'
                  as='textarea'
                  rows='5'
                  cols='5'
                  required={true}
                  css={styledInput}
                />
              </div>
            </fieldset>

            <br/>

            <button
              type='submit'
              disabled={isSubmitting}
              className='Button Button--full Button--primary'
            >
              Submit
            </button>
          </Form>

          {status && status.msg && (
            <p
              css={[alert, status.sent ? alertSuccess : alertError]}
            >
              {status.msg}
            </p>
          )}
        </>
      )}
    </Formik>
  </>
);

export default NominationForm;
